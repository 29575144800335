import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Slide from "@mui/material/Slide";
import {useRouter} from "next/router";
import {useForm} from "react-hook-form";
import clsx from "clsx";
import IconFlaticon from "@/components/IconFlaticon";
import isEmpty from "lodash/isEmpty";
import {getThemeColors} from "@/components/showcase/_helpers";
import {StyledButton, StyledInput, StyledRadioGroup} from "@/components/showcase/_controls";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import {styled} from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DefaultDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root' :{
        width: '100%',
        borderRadius: 12
    }
}))

export default function FiltersDrawer(props){
    const { push } = useRouter();
    const { control, register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm();
    const [sortByPrice, setSortByPrice] = React.useState(props.priceSort ? props.priceSort : '');
    const [filterPriceEnd, setFilterPriceEnd] = React.useState(props.priceEnd ? props.priceEnd : null);
    const [filterPriceStart, setFilterPriceStart] = React.useState(props.priceStart ? props.priceStart : null);
    const [filterCategories, setFilterCategories] = React.useState(props.categoriesActive ? props.categoriesActive : null);
    const [filterCategoriesActive, setFilterCategoriesActive] = React.useState(null);
    const [backCat, setBackCat] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const slugParents = []

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setBackCat(null)
        setFilterCategoriesActive(null)
        setOpen(false);
    };

    function getSlug(cat){
        return cat.attributes.slug
    }

    function getSlugParent(c){
        if(c.attributes?.parent?.data){
            const s1 = `${c.attributes.parent.data.attributes.slug}`
            slugParents.push({name: c.attributes.parent.data.attributes.name, slug: s1})
            getSlugParent(c.attributes.parent.data)
        }
    }

    if(filterCategories){
        slugParents.push({name: filterCategories.attributes.name, slug: filterCategories.attributes.slug})
        getSlugParent(filterCategories)
        slugParents.reverse()
    }

    function nextCat(cat){
        setBackCat(getParentCatFilter(cat))
        setFilterCategoriesActive(cat)
    }

    function setCats(cat){
        const check = filterCategories ? filterCategories.id === cat.id : false
        if(!check){
            setFilterCategories(cat)
        }
    }

    const GetEl = ({cat, slug}) => {
        const child = cat.attributes?.child?.data
        return(
            <div className={'flex flex-row items-center justify-between cursor-pointer space-x-1.5 py-4 px-4 text-sm border-0 border-b border-solid border-white-1'}
                 onClick={()=>{
                     if(child && child.length > 0 ){
                         nextCat(cat)
                     }else{
                         setCats(cat)
                         handleClose()
                     }
                 }}
            >
                <span>{cat.attributes.name}</span>
                <IconFlaticon className={!child || child.length === 0 && 'hidden'} name='rr-angle-right' />
            </div>
        )
    }

    function getParentCatFilter(e){
        return e && e.attributes.parent.data ? e.attributes.parent.data : null
    }

    function getChildCatFilter(e){
        return filterCategoriesActive ? e.attributes.parent.data?.id === filterCategoriesActive.id : e.attributes.parent.data === null
    }

    async function onSubmit(data){
        let url = '';
        setFilterPriceStart(data.start)
        setFilterPriceEnd(data.end)
        if(slugParents.length > 0){
            slugParents.map((s)=> {
                url += `/${s.slug}`
            })
        }
        switch (sortByPrice) {
            case 'disc':
                url += `/price/disc`
                break;
            case 'asc':
                url += `/price/asc`
                break;
            case 'desc':
                url += `/price/desc`
                break;
            default:
                break;
        }
        if(!isEmpty(data.start) && (isEmpty(data.end) || parseInt(data.end) > parseInt(data.start))){
            url += `/ps/${data.start}`
        }
        if(!isEmpty(data.end) && (isEmpty(data.start) || parseInt(data.end) > parseInt(data.start))){
            url += `/pe/${data.end}`
        }
        if(isEmpty(url)){
            url += '/'
        }
        await push(url);
        props.onClose();
    }

    return(
        <React.Fragment key={props.anchor}>
            <SwipeableDrawer
                anchor={props.anchor}
                open={props.isOpen}
                onClose={props.onClose}
                onOpen={props.onOpen}
            >
                <form className={'bg-white rounded-t-xl'} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex w-16 h-1 mx-auto my-4 bg-gray-3 rounded-xl'></div>
                    <div className='flex flex-col w-full space-y-6'>
                        <div className="flex flex-col space-y-2.5 py-6 px-3 border-0 border-b border-solid border-white-1  cursor-pointer">
                            <div className="flex flex-row justify-between" onClick={!filterCategories ? handleClickOpen : null}>
                                <span className="text-sm font-semibold">Категории товара</span>
                                <span style={{color: getThemeColors(props.themeColor ? props.themeColor : 0)}}
                                      className={clsx("text-sm font-semibold",!filterCategories && 'hidden')}
                                      onClick={()=>{
                                          setBackCat(null)
                                          setFilterCategoriesActive(null)
                                          setFilterCategories(null)
                                    }}
                                >
                                    Сбросить
                                </span>
                                <IconFlaticon name="sr-angle-right" className={clsx("text-gray-1",filterCategories && 'hidden')}/>
                            </div>
                            <div className={clsx("flex flex-row justify-between",!filterCategories && 'hidden')} onClick={handleClickOpen}>
                                <span className="px-3 py-1 text-sm bg-white-1 rounded-12">{filterCategories && filterCategories.attributes.name}</span>
                                <IconFlaticon name="sr-angle-right" className={clsx("text-gray-1",!filterCategories && 'hidden')}/>
                            </div>
                        </div>

                        <div className="flex flex-col space-y-3 pb-6 px-3 border-0 border-b border-solid border-white-1">
                            <div className="flex flex-row justify-between">
                                <span className="text-sm font-semibold">Цена</span>
                                <span style={{color: getThemeColors(props.themeColor ? props.themeColor : 0)}}
                                      className={clsx("text-sm font-semibold", !filterPriceEnd && !filterPriceStart && 'hidden')}
                                      onClick={()=>{
                                          setFilterPriceStart(null);
                                          setFilterPriceEnd(null);
                                          setValue('start', '')
                                          setValue('end', '')
                                    }}
                                >
                                    Сбросить
                                </span>
                            </div>
                            <div className='flex flex-row space-x-3 items-center'>
                                <StyledInput
                                    title={'От'}
                                    name={'start'}
                                    defaultValue={filterPriceStart}
                                    className={'bg-white-6 hover:bg-white'}
                                    error={errors.start}
                                    register={register}
                                    required={false}
                                    hintMargins={'mt-1'}
                                    colorScheme={props.themeColor}
                                    onKeyDown={(e) => {
                                        if(![8,37,39,46,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105].includes(e.keyCode)){
                                            e.preventDefault();
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={'bg-transparent hover:bg-transparent'}
                                                onClick={()=> setValue('start', '')}
                                                edge="end"
                                                size="small"
                                            >
                                                <IconFlaticon className='h-4.5 text-gray-4 hover:text-black-1' name='br-cross-small' />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <StyledInput
                                    title={'До'}
                                    name={'end'}
                                    defaultValue={filterPriceEnd}
                                    className={'bg-white-6 hover:bg-white'}
                                    error={errors.end}
                                    register={register}
                                    required={false}
                                    hintMargins={'mt-1'}
                                    colorScheme={props.themeColor}
                                    onKeyDown={(e) => {
                                        if(![8,37,39,46,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105].includes(e.keyCode)){
                                            e.preventDefault();
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={'bg-transparent hover:bg-transparent'}
                                                onClick={()=> setValue('end', '')}
                                                edge="end"
                                                size="small"
                                            >
                                                <IconFlaticon className='h-4.5 text-gray-4 hover:text-black-1' name='br-cross-small' />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex flex-col space-y-3 pb-6 px-3 border-0 border-b border-solid border-white-1">
                            <span className="text-sm font-semibold">Сортировка товара</span>
                            <StyledRadioGroup
                                className={"text-sm"}
                                value={sortByPrice}
                                onChange={(e)=> {
                                    setSortByPrice(e.target.value)
                                }}
                                buttonsColor={'#CBCBCB'}
                                buttonsCheckedColor={getThemeColors(props.themeColor ? props.themeColor : 0)}
                                labelPlacement={'start'}
                                labelClass={'flex justify-between ml-0 '}
                                options={[
                                    {
                                        value: '',
                                        name: 'По популярности'
                                    },
                                    {
                                        value: 'asc',
                                        name: 'По возрастанию цены'
                                    },
                                    {
                                        value: 'desc',
                                        name: 'По убыванию цены'
                                    },
                                    {
                                        value: 'disc',
                                        name: 'Сначала со скидкой'
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className={"flex w-full justify-center px-3 py-4"}>
                        <StyledButton
                            type="submit"
                            text={'Применить'}
                            className={'w-full'}
                            colorScheme={props.themeColor}
                        />
                    </div>
                </form>

                <DefaultDialog
                    // fullScreen
                    fullWidth
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    className={'rounded-xl'}
                >
                    <div className="flex flex-col w-full items-center">
                        <div className="flex flex-row w-full h-14 px-4 justify-between items-center border-solid border-0 border-b border-white-1">
                            <div className="text-sm font-semibold">
                                {filterCategoriesActive ? filterCategoriesActive.attributes.name : 'Выберите категорию'}
                            </div>
                            <div className="flex flex-col justify-center cursor-pointer" onClick={filterCategoriesActive ? ()=>{nextCat(backCat)} : handleClose}>
                                <IconFlaticon
                                    name={filterCategoriesActive ? "br-angle-left" : "br-cross"}
                                    style={{color: getThemeColors(props.themeColor ? props.themeColor : 0)}}
                                    className="flex"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col space-y-1 bg-white'>
                        {filterCategoriesActive && (
                            <div className='py-4 px-4 text-sm cursor-pointer border-0 border-b border-solid border-white-1'
                                onClick={()=> {
                                    setCats(filterCategoriesActive)
                                    handleClose()
                                }}
                            >
                                <span>Все товары</span>
                            </div>
                        )}
                        {props.categoriesList && props.categoriesList.filter((e)=> getChildCatFilter(e)).map((cat, n)=>
                            <React.Fragment key={n}>
                                <GetEl cat={cat} slug={`/${getSlug(cat)}`}  />
                            </React.Fragment>
                        )}
                    </div>
                </DefaultDialog>
            </SwipeableDrawer>
        </React.Fragment>
    )
}