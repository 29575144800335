import * as React from "react";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconFlaticon from "@/components/IconFlaticon";
import LoadingButton from "@mui/lab/LoadingButton";
import NextLink from "next/link";
import OutlinedInput from "@mui/material/OutlinedInput";
import clsx from "clsx";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from '@mui/material/CircularProgress';
import { axiosTemplate } from "../../../lib/fetchJson";
import {useRouter} from "next/router";
import CategoryPanel from "../ecommy/shop/cats/CategoryPanel";
import {motion} from "framer-motion";
import {RadioGroupCustom} from "@/components/ecommy/shop/MaterialShop";
import {ClickAwayListener} from "@mui/material";
import {getThemeColors} from "@/components/showcase/_helpers";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Image from 'next/image'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ru from "date-fns/locale/ru";
import {Controller} from "react-hook-form";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const HoverEffectImage = props => {
    function mouseOver(e) {
        e.currentTarget.src = props.altImage;
    }
    function mouseOut(e) {
        e.currentTarget.src = props.defImage;
    }
    const [isHovering, setIsHovered] = React.useState(false);
    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);
    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {isHovering ? (
                <Image
                    className={props.className}
                    src={props.altImage}
                    alt={props.altText}
                    sizes="100vw"
                    style={{
                        width: '100%',
                        height: 'auto',
                    }}
                    quality={props.imgSize.quality}
                    width={props.imgSize.width}
                    height={props.imgSize.height}
                />
            ) : (
                <Image
                    className={props.className}
                    src={props.defImage}
                    alt={props.altText}
                    sizes="100vw"
                    style={{
                        width: '100%',
                        height: 'auto',
                    }}
                    quality={props.imgSize.quality}
                    width={props.imgSize.width}
                    height={props.imgSize.height}
                />
            )}
        </div>
        // <img 
        //     className={props.className} 
        //     src={props.defImage} 
        //     onMouseOver={mouseOver} 
        //     onMouseOut={mouseOut} 
        //     alt={props.altText}
        // />
    )
}

const ThemedLink = styled(NextLink)(({ theme, colorscheme, active }) => ({
    color: active ? getThemeColors(colorscheme) : '#000000',
    textDecoration: 'none',
    '&.active': {
        color: getThemeColors(colorscheme),
        textDecoration: 'none',
    },
    '&:hover': {
        color: getThemeColors(colorscheme),
        textDecoration: 'none',
    }
}));

const ThemedButton = styled(Button)(({ theme, colorscheme, inverse }) => ({
    '&:hover': {
        backgroundColor: inverse ? '#FFFFFF' : colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        borderColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        // textDecoration: 'underline',
    },
    '&:active': {
        backgroundColor: inverse ? '#FFFFFF' : colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        borderColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        // textDecoration: 'underline',
    },
    color: inverse || (colorscheme && colorscheme === 8) ? '#000000' : '#FFFFFF',
    backgroundColor: inverse ? '#FFFFFF' : colorscheme ? getThemeColors(colorscheme) : '#758BD2',
    borderStyle: inverse ? 'solid': 'none',
    borderColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
    borderWidth: inverse ? 2 : 0,
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    borderRadius: 12,
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: "'Segoe UI', Helvetica"
}));

const ThemedIconButton = styled(IconButton)(({ theme, colorscheme }) => ({
    '&:hover': {
        backgroundColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        borderColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        // textDecoration: 'underline',
    },
    '&:active': {
        backgroundColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        borderColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        // textDecoration: 'underline',
    },
    color: colorscheme && colorscheme === 8 ? '#000000' : '#FFFFFF',
    backgroundColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
    textDecoration: 'none',
    // padding: '0.5rem 1rem',
    borderRadius: 12,
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: "'Segoe UI', Helvetica"
}));

const ButtonTransparent = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:active': {
        backgroundColor: 'transparent',
    },
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: "'Segoe UI', Helvetica"
}));

const ButtonGray = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#F5F5F5',
        borderColor: '#F5F5F5',
        // textDecoration: 'underline',
    },
    '&:active': {
        backgroundColor: '#F5F5F5',
        borderColor: '#F5F5F5',
        // textDecoration: 'underline',
    },
    color: 'black',
    backgroundColor: '#FBFBFB',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    borderRadius: 12,
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: "'Segoe UI', Helvetica"
}));

const ButtonWhite = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(255, 255, 255)',
        // textDecoration: 'underline',
    },
    '&:active': {
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(255, 255, 255)',
        // textDecoration: 'underline',
    },
    color: 'white',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    borderRadius: 12,
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: "'Segoe UI', Helvetica"
}));

const ButtonWhiteLoading = styled(LoadingButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(255, 255, 255)',
        // textDecoration: 'underline',
    },
    '&:active': {
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(255, 255, 255)',
        // textDecoration: 'underline',
    },
    color: 'white',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    borderRadius: 12,
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: "'Segoe UI', Helvetica"
}));

const ButtonPink = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(255, 255, 255)',
        // textDecoration: 'underline',
    },
    '&:active': {
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(255, 255, 255)',
        // textDecoration: 'underline',
    },
    color: 'white',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    borderRadius: 12,
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: "'Segoe UI', Helvetica"
}));

const StyledFormControl = styled(FormControl)(({ theme,colorscheme }) => ({
    '& .MuiOutlinedInput-root': {
        'fieldset': {
            borderColor: '#eeeeee',
        },
        '&:hover fieldset': {
            borderColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        },
        borderRadius: '0.75rem',
        '& .MuiOutlinedInput-input':{
            textAlign: 'left',
            padding: '0.75rem 1rem',
        },
        '& .MuiInputBase-inputMultiline':{
            textAlign: 'left',
            padding: '0.75rem 1rem',
        },
        '& .Mui-error': {
            borderColor: '#F22052'
        }
    },
    '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorscheme ? getThemeColors(colorscheme) : '#758BD2',
        }
    },
    '& .MuiFormHelperText-root' : {
        color: '#F22052'
    }
}));

const StyledFormControlLabel = styled(FormControlLabel) ({
    '& .MuiFormControlLabel-label': {
        width: '100%'
    }
})

const StyledRadioButton = styled(Radio) ({
    color: '#CBCBCB',
    '&.Mui-checked': {
        color: '#758BD2!important',
    },
    '&.MuiTypography-body1': {
        width: '100%'
    }
})

const CategoryAccordion = styled(Accordion) ({
    '&:before': {
        display: 'none',
    },
})

function BackButton(props){
    const router = useRouter();
    return(
        <div className='flex flex-row justify-between'>
            <div className="flex flex-row w-fit items-center text-black-1 cursor-pointer" onClick={() => router.back()}>
                <IconFlaticon name='rr-arrow-small-left' className='h-7 mr-4 -ml-1.5 text-3xl'/>
                <div>Назад</div>
            </div>
            {props.shopId && (
                <div className='flex flex-row items-center text-purple-1'>Shop ID: {props.shopId}</div>
            )}
        </div>
    )
}

function StyledLink(props){
    return(
        <ThemedLink scroll={props.scroll} href={props.href} className={props.className} aria-label={props.ariaLabel} active={props.isActive} colorscheme={props.colorScheme}>
            {props.text}
        </ThemedLink>
    )
}

function StyledInput(props){
    return(
        <StyledFormControl colorscheme={props.colorScheme} fullWidth>
            <div className='flex flex-row mb-1 tablet:mb-2 justify-between text-sm tablet:text-base'>
                <div>
                    {props.title}
                    {props.required && (
                        <span className='ml-0.5 text-red-1'>*</span>
                    )}
                </div>
                {props.maxLength && (
                    <div className='text-gray-9'>
                        {props.currentLength ? props.currentLength : 0}/{props.maxLength} симв.
                    </div>
                )}
            </div>
            <OutlinedInput
                fullWidth
                {...props.register(props.name)}
                id={props.name}
                name={props.name}
                type={props.type}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                autoComplete={props.name}
                className={props.className ? props.className : props.multiline ? "!p-0 text-base" : "text-base"}
                inputProps={props.maxLength && { maxLength: props.maxLength }}
                inputComponent={props.inputComponent}
                onInput={props.onInput}
                onKeyDown={props.onKeyDown}
                multiline={props.multiline}
                minRows={props.minRows}
                error={!!props.error}
                endAdornment={props.endAdornment}
            />
            <div className={clsx('text-xs tablet:text-sm', props.hintMargins ? props.hintMargins : 'mt-1 mb-7', props.error ? 'text-red-1' : 'text-gray-9')}>
                {props.error ? ( `${props.error.message}` ) : props.hint}
            </div>
        </StyledFormControl>
    )
}

function StyledDataPicker(props){
    return(
        <StyledFormControl colorscheme={props.colorScheme} fullWidth>
            <div className='flex flex-row mb-1 tablet:mb-2 justify-between text-sm tablet:text-base'>
                <div>
                    {props.title}
                    {props.required && (
                        <span className='ml-0.5 text-red-1'>*</span>
                    )}
                </div>
            </div>
            <LocalizationProvider dateAdapter={props.dateAdapter} adapterLocale={props.adapterLocale}>
                <Controller
                    control={props.control}
                    defaultValue={typeof props.defaultValue === 'string' ? new Date(props.defaultValue) : props.defaultValue}
                    name={props.name}
                    render={({ field }) => {
                        return (
                            <>
                                <MobileDatePicker
                                    value={field.value}
                                    inputRef={field.ref}
                                    onChange={(date) => {
                                        field.onChange(date);
                                    }}
                                    className={props.className}
                                    localeText={{
                                        clearButtonLabel: 'Сбросить',
                                        cancelButtonLabel: 'Отмена',
                                        okButtonLabel: 'Ок',
                                        datePickerToolbarTitle: 'Выбрать дату'
                                    }}
                                    slotProps={{
                                        dialog: { disableScrollLock: true },
                                        textField: { placeholder: 'дд.мм.гггг' },
                                        actionBar: {
                                            actions: ['clear', 'cancel', 'accept'],
                                        }
                                    }}
                                />
                                <IconButton
                                    className={'absolute flex h-7.5 w-7.5 right-1 top-10 bg-transparent hover:bg-transparent'}
                                    onClick={props.clearDate}
                                >
                                    <IconFlaticon className='flex text-lg text-gray-4 hover:text-black-1' name='br-cross-small' />
                                </IconButton>
                            </>
                        )}}
                />
            </LocalizationProvider>
            <div className={clsx('text-xs tablet:text-sm', props.hintMargins ? props.hintMargins : 'mt-1 mb-7', props.error ? 'text-red-1' : 'text-gray-9')}>
                {props.error ? ( `${props.error.message}` ) : props.hint}
            </div>
        </StyledFormControl>
    )
}

function StyledButton(props){
    return(
        <ThemedButton href={props.href} type={props.type} component={props.component} className={clsx(props.className, 'hover:brightness-90')} onClick={props.onClick} inverse={props.inverse} colorscheme={props.colorScheme}>
            {props.text}
        </ThemedButton>
    )
}

function StyledDialog(props){
    const DefaultDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiPaper-root' :{
            width: '100%',
            borderRadius: 24
        }
    }))
    return(
        <DefaultDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby={props.ariaLabel}
            aria-describedby={props.ariaDescribedBy}
        >
            <DialogTitle className={'text-base font-normal text-center py-6'}>
                {props.title}
            </DialogTitle>
            <DialogActions className={'flex flex-row items-center justify-center space-x-5 mb-8'}>
                <StyledButton
                    onClick={props.buttonSuccess}
                    className={clsx(props.buttonClass, 'h-12 px-8')}
                    text={props.buttonTitle}
                />
                <StyledButton onClick={props.buttonDecline} className='h-12 px-8 text-black bg-white-1 hover:bg-white-1' text={'Нет'}/>
            </DialogActions>
        </DefaultDialog>
    )
}

function StyledIconButton(props){
    return(
        <ThemedIconButton {...(props.title && { title: props.title })} type={props.type} variant={props.variant} className={clsx(props.className, 'hover:brightness-90')} onClick={props.onClick} colorscheme={props.colorScheme}>
            <IconFlaticon className={props.iconClass} name={props.icon} />
        </ThemedIconButton>
    )
}

function StyledRadioGroup(props){
    const RadioButton = styled(Radio) ({
        color: props.buttonsColor,
        '&.Mui-checked': {
            color: props.buttonsCheckedColor,
        },
        '&.MuiTypography-body1': {
            width: '100%'
        }
    })
    return(
        <ClickAwayListener onClickAway={props.onClickAway ? props.onClickAway : () => {}}>
            <RadioGroupCustom
                className={props.className}
                value={props.value}
                onChange={props.onChange}
            >
                {props.options && props.options.map((el,n) => {
                    return(
                        <React.Fragment key={n}>
                            <motion.div className='!py-1'>
                                <FormControlLabel
                                    className={props.labelClass}
                                    value={el.value}
                                    control={
                                        <RadioButton />
                                    }
                                    label={<div className='pl-0 tablet:pl-1 text-sm tablet:text-base'>{el.name}</div>}
                                    labelPlacement={props.labelPlacement ? props.labelPlacement : 'end'}
                                />
                            </motion.div>
                        </React.Fragment>
                    )
                })}
            </RadioGroupCustom>
        </ClickAwayListener>
    )
}

function SettingsDirectoryButton(props){
    return(
        <ButtonGray href={props.href} component={NextLink} className='flex flex-row justify-between items-center py-6 px-0 tablet:px-5 bg-white tablet:bg-white-6 border-0 border-t tablet:border-t-0 border-solid border-white-1 rounded-none tablet:rounded-xl'>
            <div className='flex flex-row'>
                <img src={props.icon} className='w-7 tablet:w-10 ml-3 tablet:ml-0 mr-4 tablet:mr-7' alt='ICON'/>
                <div className='space-y-1 text-left'>
                    <div className='text-sm tablet:text-base'>{props.name}</div>
                    <div className='text-xs tablet:text-sm text-gray-9'>{props.text}</div>
                </div>
            </div>
            <IconFlaticon name={'br-angle-right'} className={'mr-2 tablet:mr-0 text-lg tablet:text-2xl text-purple-1'}/>
        </ButtonGray>
    )
}

function SettingsCategoryListAccordion(props){
    const [expanded, setExpanded] = React.useState(0);
    const toggleAccordion = (id) => (event) => {
        setExpanded(expanded !== id ? id : 0);
    };

    return(
        <CategoryAccordion expanded={expanded === props.id}  disableGutters elevation={0} className='bg-transparent tablet:bg-white-3 px-0 tablet:px-5 py-2 border-0 border-t tablet:border-t-0 border-solid border-white-1 rounded-none tablet:rounded-12'>
            <AccordionSummary
                className='px-3 tablet:p-0'
                expandIcon={
                    <div className={clsx('ml-1',expanded === props.id ? 'pt-2' : 'pb-0')} onClick={toggleAccordion(props.id)}>
                        <IconFlaticon name={"br-angle-down"} className={"text-xl text-gray-9"}/>
                    </div>
                }
            >
                <div className='flex flex-col tablet:flex-row w-full mr-7 space-y-4 tablet:space-y-0 justify-between'>
                    <div className='flex flex-col w-full mt-3 tablet:mt-0' onClick={toggleAccordion(props.id)}>
                        <div className='pb-1 text-sm tablet:text-base'>{props.name}</div>
                        <div className='text-sm text-gray-9'>Подкатегорий: {props.subCategories ? props.subCategories.length : 0}</div>
                    </div>
                    <CategoryPanel cat={props} token={props.token} refreshCats={props.refreshCats} />
                </div>
            </AccordionSummary>
            <AccordionDetails className='flex flex-col mt-2 p-0'>
                <NextLink href={`/showcase/category-edit/?parent=${props.id}`} className='flex flex-row justify-between items-center border-0 border-t border-solid border-white-1'>
                    <div className='pl-3 tablet:px-0 text-sm tablet:text-base text-purple-1'>
                        Создать подкатегорию
                    </div>
                    <div className='flex w-7 h-7 my-2.5 mr-3 tablet:mr-0 items-center justify-center bg-purple-1 rounded-full'>
                        <IconFlaticon name={'sr-plus'} className={'pl-px pt-px text-xs text-white'}/>
                    </div>
                </NextLink>
                {props.subCategories && props.subCategories.map((el,n) => {
                    return(
                        <React.Fragment key={n}>
                            <div className='flex flex-row px-3 tablet:px-0 justify-between items-center border-0 border-t border-solid border-white-1'>
                                <div className='text-sm tablet:text-base'>
                                    {el.name}
                                </div>
                                <CategoryPanel cat={el} parentCat={props.id} token={props.token} refreshCats={props.refreshCats} />
                            </div>
                        </React.Fragment>
                    )
                })}
            </AccordionDetails>
        </CategoryAccordion>
    )
}

export {HoverEffectImage, ButtonWhiteLoading, ButtonPink, ButtonWhite, ButtonGray, ButtonTransparent, StyledDialog, StyledFormControl, StyledFormControlLabel, StyledRadioButton, StyledLink, StyledButton, StyledIconButton, StyledRadioGroup, SettingsDirectoryButton, SettingsCategoryListAccordion, StyledInput, StyledDataPicker, BackButton}