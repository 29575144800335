import {makeStyles} from "@mui/styles";
const colors = ["#758BD2", "#60A0DF", "#F287AE", "#D97DCA", "#55C9EE","#2DCBDC", "#48D89E", "#8AD964", "#EBE667", "#FFAF5E", "#FF9088", "#EC7A7A", "#56545E"];

function getThemeColors(n){
    return  colors[n];}

function getThemeStyle(n){
    const themeStyles = makeStyles({
        textColorMain: {
            color: colors[n]
        },
        textHoverMain: {
            '&:hover': {
                color: colors[n]
            }
        },
        logoTitle: {
            color: colors[n]
        },
        headerIcons: {
            color: colors[n]
        },
        headerButtons: {
            color: '#0E0E16',
            '&:hover': {
                color: colors[n]
            },
        },
        inputs: {
            borderStyle: 'solid',
            borderColor: colors[n],
            '&:hover': {
                borderColor: colors[n]
            }
        },
        buttons: {
            color: n === 8 ? '#000000' : '#FFFFFF',
            backgroundColor: colors[n],
            '&:hover': {
                color: n === 8 ? '#000000' : '#FFFFFF',
                backgroundColor: colors[n],
            }
        },
        buttonsAlt: {
            color: '#000000',
            borderStyle: 'solid',
            borderColor: colors[n],
            backgroundColor: '#FFFFFF',
            '&:hover': {
                color: '#000000',
                borderStyle: 'solid',
                borderColor: colors[n],
                backgroundColor: '#FFFFFF',
            }
        },
        breadcrumbs: {
            color: '#000000',
            '&:hover': {
                color: colors[n]
            },
            '&:active': {
                color: colors[n]
            }
        },
        breadcrumbsActive: {
            color: colors[n]
        }
    })
    return themeStyles();
}

function correctEndings(n,s){
    if(Array.isArray(s)){
        if(n % 10 === 1 && (n % 100 === 1 || n % 100 > 20)){
            return s[0]
        }else if((n % 10 > 1 && n % 10 < 5) && (n % 100 < 5 || n % 100 > 21)){
            return s[1]
        }else{
            return s[2]
        }
    }else{
        return null;
    }
}

function getLegalName(u){
    if(u.name && u.type){
        switch (u.type){
            case 'physical':
                return u.name;
            case 'ooo':
                return u.name.startsWith('ООО') ? u.name : 'ООО '+u.name;
            case 'ip':
                return u.name.startsWith('ИП') ? u.name : 'ИП '+u.name;
        }
    }else{
        return null;
    }
}

export {correctEndings, getLegalName, getThemeStyle, getThemeColors}