import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import InputBase from "@mui/material/InputBase";
import {getThemeColors} from "@/components/showcase/_helpers";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import IconFlaticon from "@/components/IconFlaticon";
import {StyledButton, StyledIconButton} from "@/components/showcase/_controls";
import {useRouter} from "next/router";
import {useForm} from "react-hook-form";
import isEmpty from "lodash/isEmpty";

export default function SearchDrawer(props){
    const { push } = useRouter();
    const { control, register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm();

    async function onSubmit(data){
        props.setSearchValue(data.textName)
        let url = '/'
        if(!isEmpty(data.textName)){
            url = `/text/${data.textName}`
        }
        await push(url)
        props.onClose();
    }

    function clearText(){
        setValue('textName', '')
        props.setSearchValue('')
    }

    return(
        <React.Fragment key={props.anchor}>
            <SwipeableDrawer
                anchor={props.anchor}
                open={props.isOpen}
                onClose={props.onClose}
                onOpen={props.onOpen}
            >
                <form className={'bg-white rounded-t-xl'} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex w-16 h-1 mx-auto my-4 bg-gray-3 rounded-xl'></div>
                    <div className="flex flex-row w-full py-6 px-3 border-0 border-b border-solid border-white-1">
                        <InputBase
                            style={{borderColor: getThemeColors(props.themeColor ? props.themeColor : 0)}}
                            className="w-full text-sm border-solid border-2 rounded-l-12"
                            sx={{ px: 1.5, flex: 1 }}
                            defaultValue={props.searchValue}
                            {...register("textName")}
                            placeholder="Поиск товара"
                            inputProps={{ 'aria-label': 'Поиск товара' }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        className={'bg-transparent hover:bg-transparent'}
                                        onClick={()=>clearText()}
                                        edge="end"
                                        size="small"
                                    >
                                        <IconFlaticon className='h-4.5 text-gray-4 hover:text-black-1' name='br-cross-small' />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <StyledIconButton
                            type="submit"
                            variant="contained"
                            className={'text-white rounded-l-none rounded-r-12 px-2.5 py-2.5 shadow-none hover:shadow-none normal-case'}
                            icon={'br-search'}
                            iconClass={'mr-1 pt-0.5'}
                            colorScheme={props.themeColor ? props.themeColor : 0}
                        />
                    </div>
                    <div className={"flex w-full justify-center px-3 py-4"}>
                        <StyledButton
                            type="submit"
                            text={'Применить'}
                            className={'w-full'}
                            colorScheme={props.themeColor}
                        />
                    </div>
                </form>
            </SwipeableDrawer>
        </React.Fragment>
    )
}