import * as React from "react";
import {useRouter} from "next/router";
import { useSWRConfig } from 'swr'
import {useSelector} from "react-redux";
import {ButtonPink, ButtonTransparent} from "@/components/showcase/_controls";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import NextLink from "next/link";
import {correctEndings, getThemeColors} from "@/components/showcase/_helpers";
import IconFlaticon from "@/components/IconFlaticon";
import {axiosTemplate, getShop} from "../../../lib/fetchJson";
import clsx from "clsx";
import FiltersDrawer from "@/components/showcase/FiltersDrawer";
import SearchDrawer from "@/components/showcase/SearchDrawer";
import isEmpty from 'lodash/isEmpty';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 2,
      top: 6,
    },
  }));

const StyledBadge2 = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -4,
      top: 6,
    },
}));

export default function Header(params){
    const { cache, mutate } = useSWRConfig()
    const router = useRouter();
    const start = (router.asPath === '/showcase/profile') && (!params.userInfo?.shopDescription || isEmpty(params.userInfo?.shopDescription))
    // const urlFilter = router.asPath
    // const urlFilterMobile = `/filters${urlFilter}`
    // const urlFilterSearchMobile = `/search${urlFilter}`
    
    const cart = useSelector(({ cart }) => cart);
    const getQ = () => {
        return cart.cartItems.reduce((a, c) => a + c.qty, 0)
    }

    function getShopNameStyle(){
        let style = 1
        if(params.userInfo?.shopNameStyle && params.userInfo?.shopLogo){
            style = params.userInfo?.shopNameStyle
        }else if(!params.userInfo?.shopNameStyle && params.userInfo?.shopLogo){
            style = 0
        }
        return style
    }

    function getNotyPay(){
        let res = { type: 0, show: false, daysLeft: 0 }
        if(params.showExit){
            let diff = (new Date(params.userInfo?.shop_date_end).getTime() - new Date().getTime()) / 1000 / 3600 / 24
            if(diff < 1){
                diff = 1
            }
            diff = Math.ceil(diff)
            if(diff <= 7){
                res.show = params.userInfo?.noty?.show
                res.type = 3
                res.daysLeft = diff
            }
            if(params.userInfo?.pays_count === 0){
                res.show = true
                res.type = 0
            }else{
                if(!params.userInfo?.confirmed){
                    res.show = params.userInfo?.noty?.show
                    res.type = 2
                }
            }
            if(params.userInfo?.is_shop_blocked){
                res.show = true
                res.type = 1
            }
        }
        return res
    }

    async function setNotyHidden(){
        const res = await axiosTemplate(`/api/noty`, "POST", null, true, null)
        if(res.data){
            router.replace(router.asPath)
        }
    }

    const [notificationType, setNotificationType] = React.useState(getNotyPay().type);
    const notificationShow = start ? false : (params.showNotification ? params.showNotification : (getNotyPay().show))
    const notificationText = [
        'Ваш интернет-магазин станет доступен посетителям после оплаты аренды.',
        'Ваш интернет-магазин был скрыт. Пожалуйста, оплатите аренду, и магазин снова будет виден покупателям.',
        `Оплата аренды прошла успешно. Совсем скоро мы перенесем ваш магазин на адрес ${params.userInfo?.shopPrefix}.ecommy.ru`,
        `До завершения срока аренды магазина осталось: ${getNotyPay().daysLeft} ${correctEndings(getNotyPay().daysLeft,['день','дня','дней'])}`
    ]
    const notificationStyle = [
        {
            bgColor: '#FFEBF0',
            textColor: '#FF4A75'
        },
        {
            bgColor: '#FFEBF0',
            textColor: '#FF4A75'
        },
        {
            bgColor: '#F6F8FF',
            textColor: '#5973C8'
        },
        {
            bgColor: '#F6F8FF',
            textColor: '#5973C8'
        }
    ]

    const [badgeStyle, setBadgeStyle] = React.useState(1);
    const [drawerFilter, setDrawerFilter] = React.useState(false);
    const [drawerSearch, setDrawerSearch] = React.useState(false);

    const themeColor = getThemeColors(params.userInfo ? params.userInfo.shopColor : 0);
    const ThemedButton = styled('div')({
        color: '#0E0E16',
        '&:hover': {
            color: themeColor
        },
    });
    const ThemedIcon = styled('i')({
        color: '#4D4D4D',
        backgroundColor: '#F5F5F5',
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: themeColor
        },
    })
    const ThemedHeaderButton = styled('div')({
        '& .header-button-icon': {
            color: '#4D4D4D',
            backgroundColor: '#F5F5F5',
        },
        '& .header-button-text': {
            color: '#0E0E16',
        },
        '&:hover': {
            '& .header-button-icon': {
                color: '#FFFFFF',
                backgroundColor: themeColor
            },
            '& .header-button-text': {
                color: themeColor
            }
        },
    })

    async function exit(){
        await axiosTemplate(`/api/logout`, "POST", null, true, null)
        cache.delete('/api/user')
        await router.replace(router.asPath)
    }

    return(
        <>
            <div className={notificationShow ? 'h-48 tablet:h-44' : 'h-28'}/>
            <div className='z-20 fixed flex flex-col w-full bg-white justify-center'>
                <div className='flex flex-row w-full h-15 tablet:h-20 pl-3 pr-4 justify-center items-center border-solid border-0 border-b border-white-1'>
                    {params.type && params.type === 'compact' && (
                        <div className={clsx(params.showBack && 'w-full justify-between', 'flex flex-row')}>
                            {params.showBack && (
                                <div className='flex flex-col justify-center' onClick={() => router.back()}>
                                    <IconFlaticon style={{color: themeColor}} className={'flex text-3xl'} name={'rr-arrow-left'}/>
                                </div>
                            )}
                            {params.showDemo ? (
                                <div className='flex flex-row w-130 justify-between items-center'>
                                    <NextLink href='/'>
                                        <img src={"/assets/showcase/images/logo.svg"} className="w-48 h-16" alt='LOGO'/>
                                    </NextLink>
                                    <ButtonPink href={'https://topshop.ecommy.ru'} component={NextLink} target={'_blank'} className='bg-gradient-to-r from-pink-1 to-purple-1 hover:brightness-90'>
                                        Посмотреть демонстрацию
                                    </ButtonPink>
                                </div>
                            ) : (
                                <NextLink href='/'>
                                    <img src={"/assets/showcase/images/logo.svg"} className="w-48 h-16" alt='LOGO'/>
                                </NextLink>
                            )}
                        </div>
                    )}
                    {params.type && params.type === 'full' && (
                        <div className='flex flex-row w-full max-w-750 justify-between'>
                            <div className='flex flex-row items-center'>
                                {params.showBack ? (
                                    <div className='flex flex-col justify-center' onClick={() => router.back()}>
                                        <IconFlaticon style={{color: themeColor}} className={'mt-1 flex text-3xl'} name={'rr-arrow-left'}/>
                                    </div>
                                ) : (
                                    <NextLink href='/' className='flex flex-row items-center'>
                                        {[0, 2].includes(getShopNameStyle()) && (
                                            params.userInfo && params.userInfo.shopLogo && (
                                                <img className='max-h-10 mr-2 tablet:mr-5' src={params.userInfo && params.userInfo.shopLogo ? process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL+params.userInfo.shopLogo.url : '/assets/showcase/images/logo-dummy.svg'} alt='LOGO'/>
                                            )
                                        )}
                                        {[1, 2].includes(getShopNameStyle()) && (
                                            <div className='flex flex-col'>
                                                <div style={{color: themeColor}} className={'w-40 tablet:w-auto mt-1 tablet:mt-0 text-28px font-bebas font-bold truncate'}>{params.userInfo && params.userInfo.shopTitle ? params.userInfo.shopTitle : 'Магазин 1'}</div>
                                                {/*<div className='hidden tablet:flex w-40 tablet:w-auto text-sm text-black-2 truncate'>{params.userInfo && params.userInfo.shopDescription ? params.userInfo.shopDescription : 'Описание вашего магазина'}</div>*/}
                                            </div>
                                        )}
                                    </NextLink>
                                )}
                            </div>
                            <div className='flex flex-row mt-1 tablet:mt-0 space-x-4 tablet:space-x-12 items-center'>
                                {params.showBalance && (
                                    <NextLink href='' className='flex flex-row items-center'>
                                        {/*<img src='/assets/showcase/images/balance.svg' className='w-6' alt='BALANCE'/>*/}
                                        <ThemedButton className={'hidden tablet:flex ml-5'}>Баланс: 0 руб.</ThemedButton>
                                    </NextLink>
                                )}
                                {params.showSettings && (
                                    <NextLink href="/showcase/settings" className='flex flex-row items-center'>
                                        <ThemedHeaderButton className={'flex flex-row items-center'}>
                                            <IconFlaticon className={'flex w-9 h-9 items-center justify-center text-xl rounded-full header-button-icon'} name={'rr-settings'}/>
                                            <div className={'hidden tablet:flex ml-3 header-button-text'}>Управление магазином</div>
                                        </ThemedHeaderButton>
                                    </NextLink>
                                )}
                                {params.showCart && (
                                    <NextLink href='/cart' className='flex flex-row items-center'>
                                        {badgeStyle === 0 && (
                                            <>
                                                <StyledBadge badgeContent={getQ()} color="error">
                                                    <IconFlaticon style={{color: themeColor}} className={'mt-1 text-2xl'} name={'rr-shopping-cart'}/>
                                                </StyledBadge>
                                                <ThemedButton className={'hidden tablet:flex ml-4'}>Корзина</ThemedButton>
                                            </>
                                        )}
                                        {badgeStyle === 1 && (
                                            <ThemedHeaderButton className={'flex flex-row items-center'}>
                                                <div className='relative'>
                                                    <IconFlaticon className={'flex w-9 h-9 items-center justify-center text-xl rounded-full header-button-icon'} name={'rr-shopping-cart'}/>
                                                    {getQ() > 0 && (
                                                        <div className={'absolute -top-1 -right-1 h-3.5 w-3.5 border-2 border-solid border-white rounded-full'} style={{backgroundColor: themeColor}}/>
                                                    )}

                                                </div>
                                                <div className={'hidden tablet:flex ml-3 header-button-text'}>Корзина</div>
                                            </ThemedHeaderButton>
                                        )}
                                    </NextLink>
                                )}
                                {params.showExit ? (
                                    <ThemedHeaderButton className={'flex flex-row items-center cursor-pointer'} onClick={exit}>
                                        <IconFlaticon className={'flex w-9 h-9 items-center justify-center text-lg rounded-full header-button-icon'} name={'rr-exit'}/>
                                        <div className={'hidden tablet:flex ml-3 header-button-text'}>Выйти</div>
                                    </ThemedHeaderButton>
                                ) : (
                                    <NextLink href="/login" className='flex flex-row items-center'>
                                        <ThemedHeaderButton className={'flex flex-row items-center'}>
                                            <IconFlaticon className={'flex w-9 h-9 items-center justify-center text-xl rounded-full header-button-icon'} name={'rr-user'}/>
                                            <div className={'hidden tablet:flex ml-3 header-button-text'}>Войти</div>
                                        </ThemedHeaderButton>
                                    </NextLink>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {params.showFilters && (
                    <div className='flex flex-row w-full items-center border-solid border-0 border-b border-white-1'>
                        <ButtonTransparent onClick={() => setDrawerFilter(true)} className='flex flex-row w-1/2 h-10 items-center justify-center text-black font-semibold border-0 border-r border-solid border-white-1 rounded-none'>
                            <StyledBadge2 color="error" variant="dot" invisible={!params.checkFilter}>
                                <div className='flex flex-row items-center justify-center'>
                                    <IconFlaticon className={'flex mr-2.5 text-gray-7'} name={'br-filter'}/>
                                    <span>Фильтр</span>
                                </div>
                            </StyledBadge2>
                        </ButtonTransparent>
                        <ButtonTransparent onClick={() => setDrawerSearch(true)} className='flex flex-row w-1/2 h-10 items-center justify-center text-black font-semibold rounded-none'>
                            <StyledBadge2 color="error" variant="dot" invisible={!params.checkText}>
                                <div className='flex flex-row items-center justify-center'>
                                    <IconFlaticon className={'flex mr-2.5 text-gray-7'} name={'br-search'}/>
                                    <span>Поиск</span>
                                </div>
                            </StyledBadge2>
                        </ButtonTransparent>
                    </div>
                )}
                {notificationShow  && (
                    <div style={{backgroundColor: notificationStyle[notificationType].bgColor}} className={'flex flex-row w-full h-24 tablet:h-16 pl-3 pr-4 justify-center items-center'}>
                        <div className={'flex flex-row max-w-750 w-full justify-between items-center'}>
                            <div className={'flex flex-row w-full space-x-6 justify-center items-center'}>
                                <div style={{color: notificationStyle[notificationType].textColor}} className={'text-sm tablet:text-base'}>
                                    {notificationText[notificationType]}
                                </div>
                                {[0,1,3].includes(notificationType) && (
                                    <NextLink href='/showcase/payments' className={clsx([0,1].includes(notificationType) && 'h-9 bg-pink-6', [2,3].includes(notificationType) && 'h-12 tablet:h-9 bg-purple-1', 'flex px-6 items-center text-sm text-center tablet:text-base text-white rounded-lg hover:brightness-90')}>
                                        Оплатить {notificationType === 3 && ('сейчас')}
                                    </NextLink>
                                )}
                            </div>
                            {[2,3].includes(notificationType) && (
                                <div className={'hidden tablet:flex cursor-pointer hover:brightness-90'} onClick={setNotyHidden}>
                                    <IconFlaticon className={'flex w-6 h-6 pl-px items-center justify-center text-base text-white bg-gray-20 rounded-full'} name={'br-cross-small'}/>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {params.showFilters && (
                <>
                    <FiltersDrawer
                        anchor={'bottom'}
                        isOpen={drawerFilter}
                        onClose={() => setDrawerFilter(false)}
                        onOpen={() => setDrawerFilter(true)}
                        themeColor={params.userInfo ? params.userInfo.shopColor : 0}
                        priceSort={params.filterParams && params.filterParams.priceSort}
                        categoriesList={params.filterParams && params.filterParams.categoriesList}
                        categoriesActive={params.filterParams && params.filterParams.categoriesActive}
                        priceStart={params.filterParams && params.filterParams.priceStart}
                        priceEnd={params.filterParams && params.filterParams.priceEnd}
                    />
                    <SearchDrawer
                        anchor={'bottom'}
                        isOpen={drawerSearch}
                        onClose={() => setDrawerSearch(false)}
                        onOpen={() => setDrawerSearch(true)}
                        themeColor={params.userInfo ? params.userInfo.shopColor : 0}
                        searchValue={params.filterParams && params.filterParams.searchValue}
                        setSearchValue={params.filterParams && params.filterParams.setSearchValue}
                    />
                </>
            )}
        </>
    )
}