import * as React from "react";
import { useSnackbar } from 'notistack';
import { useRouter } from 'next/router'
import NextLink from "next/link";
import clsx from "clsx";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import IconFlaticon from "../../../IconFlaticon"
import { axiosTemplate } from "../../../../../lib/fetchJson";
import {StyledDialog} from "@/components/showcase/_controls";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CategoryPanel({ cat, parentCat, token, refreshCats  }){
    const { enqueueSnackbar } = useSnackbar();
    const { replace, push, asPath, pathname } = useRouter();
    const [deleteLoading, setDeleteLoading] = React.useState({ id: null })
    const [hideLoading, setHideLoading] = React.useState({ id: null })
    const [open, setOpen] = React.useState(false);
    const [openHide, setOpenHide] = React.useState(false);
    const screenMobile = useMediaQuery('(max-width:640px)');

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpenHide = () => {
        setOpenHide(true)
    }
    const handleCloseHide = () => {
        setOpenHide(false)
    }

    async function sendDel(id){
        handleClose()
        setDeleteLoading({ id })
        if(cat.subCategories && cat.subCategories.length > 0){
            cat.subCategories.forEach(async (sub)=>{
                await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories/${sub.id}`, "DELETE", null, true, token)
            })
        }
        const response = await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories/${id}`, "DELETE", null, true, token)
        await refreshCats()
        enqueueSnackbar(`Категория "${cat.name}" удалена`, { variant: "success" });
        setDeleteLoading({ id: null })
    }

    async function sendHide(id, notShow){
        handleCloseHide()
        setHideLoading({ id })
        const response = await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories/${id}`, "PUT", { data: { notShow: !notShow } }, true, token)
        if(parentCat && (notShow === true)){
            await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories/${parentCat}`, "PUT", { data: { notShow: false } }, true, token)
        }
        if(cat.subCategories && cat.subCategories.length > 0){
            await Promise.all(cat.subCategories.map(async (sub) => {
                await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories/${sub.id}`, "PUT", { data: { notShow: !notShow } }, true, token)
            }))
        }
        await refreshCats()
        enqueueSnackbar(`Категория "${cat.name}" ${!notShow ? 'скрыта' : 'показана' }`, { variant: "success" });
        setHideLoading({ id: null })
    }

    return(
        <div className={clsx(parentCat ? 'my-2.5': '', 'flex flex-row space-x-3')}>
            <NextLink
                title={'Редактировать категорию'}
                href={`/showcase/category-edit/${cat.id}${ parentCat ? `?parent=${parentCat}` : ''}`}
                className={clsx( parentCat ? `w-7 h-7` : 'w-9 h-9', 'flex items-center justify-center bg-gray-2 hover:bg-purple-1 text-gray-9 hover:text-white rounded-full')}>
                <IconFlaticon name={'sr-pencil'} className={parentCat ? 'pl-px pt-px text-xs' : ''}/>
            </NextLink>
            {hideLoading.id === cat.id ? (
                <div className={clsx( parentCat ? `w-7 h-7` : 'w-9 h-9', 'flex items-center justify-center')}>
                    <CircularProgress color="error" />
                </div>
            ):(
                <div
                    title={cat.notShow ? 'Показать категорию' : 'Скрыть категорию'}
                    onClick={handleClickOpenHide}
                    className={clsx(parentCat ? `w-7 h-7` : 'w-9 h-9', cat.notShow ? 'bg-pink-2 text-white' : 'bg-gray-2 hover:bg-pink-2 text-gray-9 hover:text-white', 'flex items-center justify-center rounded-full cursor-pointer')}
                >
                    <IconFlaticon name={cat.notShow ? 'sr-eye-crossed' : 'sr-eye'} className={'mt-px ml-px'}/>
                </div>
            )}
            {deleteLoading.id === cat.id ? (
                <div className={clsx( parentCat ? `w-7 h-7` : 'w-9 h-9', 'flex items-center justify-center')}>
                    <CircularProgress />
                </div>
            ):(
                <div
                    title={'Удалить категорию'}
                    onClick={handleClickOpen}
                    className={clsx( parentCat ? `w-7 h-7` : 'w-9 h-9', 'flex items-center justify-center bg-gray-2 hover:bg-pink-2 text-gray-9 hover:text-white rounded-full cursor-pointer')}
                >
                    <IconFlaticon name={'sr-trash'} className={''}/>
                </div>
            )}
            <StyledDialog
                open={open}
                onClose={handleClose}
                ariaLabell="Удаление категории"
                ariaDescribedBy="Удаление категории"
                title={parentCat ? 'Вы уверены, что хотите удалить подкатегорию? Все товары в ней переместятся в раздел “Товары без категории” и будут скрыты до назначения новой категории. Это действие нельзя отменить.' : 'Вы уверены, что хотите удалить категорию? Все вложенные подкатегории также будут удалены, а товары переместятся в раздел “Товары без категории” и будут скрыты до назначения новой категории. Это действие нельзя отменить.'}
                buttonTitle={<><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-trash'}/>{screenMobile ? 'Да' : 'Да, удалить'}</>}
                buttonClass={'bg-pink-2 hover:bg-pink-2'}
                buttonSuccess={async ()=> await sendDel(cat.id)}
                buttonDecline={handleClose}
            />
            <StyledDialog
                open={openHide}
                onClose={handleCloseHide}
                ariaLabell="Скрыть категорию"
                ariaDescribedBy="Скрыть категорию"
                title={cat.notShow ? parentCat ? 'Вы уверены, что хотите показать подкатегорию? Товары подкатегории также будут показаны в магазине.' : 'Вы уверены, что хотите показать категорию? Все вложенные подкатегории и товары также будут показаны в магазине.' : parentCat ? 'Вы уверены, что хотите скрыть подкатегорию? Товары подкатегории будут скрыты в магазине, пока вы снова не разрешите их отображение.' : 'Вы уверены, что хотите скрыть категорию? Все вложенные подкатегории и товары также будут скрыты в магазине, пока вы снова не разрешите их отображение.'}
                buttonTitle={cat.notShow ? <><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-eye'}/>{screenMobile ? 'Да' : 'Да, показать'}</> : <><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-eye-crossed'}/>{screenMobile ? 'Да' : 'Да, скрыть'}</>}
                buttonSuccess={async ()=> await sendHide(cat.id, cat.notShow)}
                buttonDecline={handleCloseHide}
            />
        </div>
    )
}