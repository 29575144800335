import RadioGroup from '@mui/material/RadioGroup';
import Pagination from '@mui/material/Pagination';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import {Swiper, SwiperSlide, useSwiperSlide} from "swiper/react";
import useMediaQuery from '@mui/material/useMediaQuery';


export const SwiperDTLanding = styled(Swiper)(({ theme }) => ({
  paddingLeft: '0rem !important',
  [theme.breakpoints.down('lg')]: {
      paddingLeft: '1rem !important',
  },
  // [theme.breakpoints.up('desktop-lg')]: {
  //     '--paddingLeft1': 'calc(100% - 128rem)',
  //     '--paddingLeft2': 'calc(var(--paddingLeft1) / 2)',
  //     paddingLeft: 'var(--paddingLeft2) !important',
  // },
  '& .swiper-slide': {
      marginRight: '1rem',
      width: useMediaQuery((theme) => theme.breakpoints.up('lg')) ? '18rem !important' : '9.25rem !important',
      // [theme.breakpoints.down('lg')]: {
      //     width: '11 !important',
      // }
  }
}));

export const RadioGroupCustom = styled(RadioGroup)(({ theme }) => ({
  '& .Mui-checked': {
      color: '#00C805',
  },
  '& .MuiRadio-root':{
      padding: '5px 9px'
  }
}));

export const GreenFilterFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',  
    backgroundColor: '#F9F9F9',
    input: {
      '&:hover':{
        backgroundColor: '#FFFFFF',
        color: '#000',
      },
      color: '#989898',
    },
    'fieldset': {
      borderColor: '#F9F9F9',
    },
    '&:hover fieldset': {
      borderColor: '#62E965 !important',
    },
    '& .MuiOutlinedInput-input':{
      padding: '8px 14px',
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#62E965 !important',
    }
  },
});

export const YellowFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#F9CD3E !important',
    },
    borderRadius: '16px',
    '& .MuiOutlinedInput-input':{
      padding: '14px 14px',
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F9CD3E !important',
    }
  },
});

export const GreenFormControl = styled(FormControl)({
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#62E965 !important',
      },
      borderRadius: '16px',  
      '& .MuiOutlinedInput-input':{
        padding: '14px 14px',
      },
    },
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#62E965 !important',
      }
    },
});

export const BootstrapFormControl = styled(FormControl)(({ theme }) => ({
  "& .Mui-error": {
    '& .MuiInputBase-input': {
      padding: '11px 11px',
      border: '2px solid',
      borderColor: '#F3547A',
    }
  }
}));


export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '16px',
    position: 'relative',
    border: '1px solid',
    borderColor: '#DEDEDE',
    padding: '12px 12px',
    '&:focus': {
      padding: '11px 11px',
      border: '2px solid',
      borderColor: '#62E965',
    },
  },
}));

export const GreenTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#4d7c0f',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#65a30d',
    },
  }
});

export const ButtonGreen = styled(LoadingButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#65a30d',
    borderColor: '#65a30d'
  },
  '&:active': {
    backgroundColor: '#65a30d',
    borderColor: '#65a30d'
  },
  color: 'rgb(255, 255, 255)',
  backgroundColor: '#84cc16',
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica"
}));

export const PaginationCustom = styled(Pagination)(({ theme }) => ({ 
  '& .MuiPaginationItem-root': {
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
      backgroundColor: '#F2F2F2',
      '&:hover': {
          color: 'rgb(255, 255, 255)',
          backgroundColor: '#758BD2!important'
      },
  },
  '& .Mui-selected': {
      color: 'rgb(255, 255, 255)',
      backgroundColor: '#758BD2!important',
      '&:hover': {
          backgroundColor: '#5FD961!important'
      },
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
  },
}));

export const FormSelectCustom = styled(FormControl)(({ theme }) => ({ 
  '& label.Mui-focused': {
    color: '#65a30d !important',
  },
  '& .Mui-focused': {
    '& fieldset': {
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
      borderColor: '#65a30d !important',
    }
  },
}));

export const GreenMenuItem = styled(MenuItem)(({ theme }) => ({ 
  '& .Mui-selected':{
    backgroundColor: 'rgb(247 254 231) !important',
  }
}));